import React from 'react';
import { useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';
import PaginatedItems from './PaginatedItems';
import NonPaginatedItems from './NonPaginatedItems';
import { FlowType } from './types';
import { EmptyStateContainer } from './styled';
import { FlyerItemCardSize } from 'app/mobile-desktop-common-components/FlyerItemCard';

const IframeFlyerItems: React.FC = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const storeIds = params.get('storeIds')?.split(',').filter(Boolean) || [];
  const flowType = (params.get('flowType') as FlowType) || FlowType.PAGINATION;
  const limit = parseInt(params.get('limit') || '100', 10);
  const size = (params.get('size') as FlyerItemCardSize) || 'small';
  const hoursOffset = params.get('hoursOffset')
    ? parseFloat(params.get('hoursOffset')!)
    : undefined;

  if (!storeIds.length) {
    return (
      <EmptyStateContainer>
        <Typography variant="h6" color="error">
          Store Details Not Provided
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
          Please provide store IDs to view the items.
        </Typography>
      </EmptyStateContainer>
    );
  }

  return (
    <div>
      {flowType === FlowType.NO_PAGINATION ? (
        <NonPaginatedItems
          storeIds={storeIds}
          limit={limit}
          size={size}
          hoursOffset={hoursOffset}
        />
      ) : (
        <PaginatedItems
          storeIds={storeIds}
          size={size}
          hoursOffset={hoursOffset}
        />
      )}
    </div>
  );
};

export default IframeFlyerItems;
