import React, { useState, useEffect, useMemo } from 'react';
import { GfFlyerItem } from '@swagger/typescript-fetch-goflyer/dist/api';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Chip,
  Skeleton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import { getDaysLeftOfFlyerItem } from 'utils/dtoUtility';

export type FlyerItemCardSize = 'default' | 'small';

const StyledCard = styled(Card)<{ size?: FlyerItemCardSize }>(
  ({ theme, size = 'default' }) => ({
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.spacing(size === 'small' ? 2 : 2.5),
    backgroundColor: theme.palette.background.paper,
    transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
    border: `1px solid ${alpha(theme.palette.divider, 0.08)}`,
    overflow: 'hidden',
    '&:hover': {
      transform: 'translateY(-6px)',
      boxShadow: `
      0 12px 24px -10px ${alpha(theme.palette.common.black, 0.15)},
      0 4px 8px -4px ${alpha(theme.palette.common.black, 0.1)}
    `,
      '& .image-container::after': {
        opacity: 1,
      },
      '& .image-container img': {
        //   transform: 'scale(1.02)',
      },
    },
  }),
);

const ImageContainer = styled(Box)<{ size?: FlyerItemCardSize }>(
  ({ theme, size = 'default' }) => ({
    position: 'relative',
    height: size === 'small' ? '200px' : '280px',
    backgroundColor: alpha(theme.palette.primary.main, 0.03),
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: `linear-gradient(
      180deg, 
      ${alpha(theme.palette.common.black, 0)} 0%,
      ${alpha(theme.palette.common.black, 0.02)} 40%,
      ${alpha(theme.palette.common.black, 0.05)} 100%
    )`,
      opacity: 0,
      transition: 'opacity 0.4s ease',
    },
  }),
);

const StyledChip = styled(Chip)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  fontWeight: 600,
  fontSize: '0.75rem',
  padding: '0 8px',
  height: '28px',
  backdropFilter: 'blur(8px)',
  transition: 'all 0.3s ease',
  boxShadow: `0 2px 4px ${alpha(theme.palette.common.black, 0.1)}`,
  '&.exclusive': {
    backgroundColor: alpha(theme.palette.secondary.main, 0.95),
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  '&.discount': {
    backgroundColor: alpha(theme.palette.error.main, 0.95),
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

const PriceContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'baseline',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(0.5),
}));

const Image = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  transition: 'transform 0.3s ease',
});

const ImageLoadingContainer = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#f5f5f5',
});

const ExpiryChip = styled(Chip)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(0),
  right: theme.spacing(0),
  fontSize: '0.75rem',
  height: '24px',
  borderRadius: '2px',
  backgroundColor: theme.palette.grey[500],
  color: theme.palette.warning.contrastText,
  '&.expiring-soon': {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.contrastText,
  },
}));

const ContentWrapper = styled(CardContent)<{ size?: FlyerItemCardSize }>(
  ({ theme, size = 'default' }) => ({
    padding: theme.spacing(size === 'small' ? 1.5 : 2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0),
    flex: 1,
  }),
);

const PriceText = styled(Typography)<{ size?: FlyerItemCardSize }>(
  ({ theme, size = 'default' }) => ({
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: `${1.5 * (size === 'small' ? 0.8 : 1)}rem`,
    lineHeight: 1.2,
    letterSpacing: '-0.02em',
    transition: 'color 0.2s ease',
  }),
);

const OriginalPriceText = styled(Typography)(({ theme }) => ({
  color: alpha(theme.palette.text.secondary, 0.7),
  textDecoration: 'line-through',
  fontSize: '0.875rem',
  fontWeight: 500,
}));

interface Props {
  item: GfFlyerItem;
  size?: FlyerItemCardSize;
}

function extractPrice(regularPriceString: string): number | null {
  const pricePattern = /\$?(\d+(\.\d{1,2})?)(?!\D*\d)/;
  const match = regularPriceString.match(pricePattern);
  if (match) {
    return parseFloat(match[1]);
  }
  return null;
}

const FlyerItemCard: React.FC<Props> = React.memo(
  ({ item, size = 'small' }) => {
    const [imageState, setImageState] = useState({
      loading: true,
      error: false,
      src: '',
    });

    const { discount, formattedPrice, formattedRegularPrice, quantityText } =
      useMemo(() => {
        const { salePrice, regularPriceString, unit, unitV2, quantity } = item;

        const extractedRegularPrice = regularPriceString
          ? extractPrice(regularPriceString)
          : null;

        return {
          displayUnit: unitV2?.name || unit,
          discount:
            extractedRegularPrice && salePrice
              ? Math.round((1 - salePrice / extractedRegularPrice) * 100)
              : null,
          formattedPrice: salePrice?.toFixed(2),
          formattedRegularPrice: extractedRegularPrice?.toFixed(2),
          quantityText: quantity
            ? `${quantity} ${unitV2?.name || unit} for $${salePrice}`
            : `per ${unitV2?.name || unit}`,
        };
      }, [item]);

    const daysLeft = useMemo(() => getDaysLeftOfFlyerItem(item), [item]);

    useEffect(() => {
      let mounted = true;
      const imageUrl =
        item.gfMultiImages?.[0]?.gfImage.src ||
        item.gfMultiImages?.[0]?.gfImage.srcSmall;

      if (!imageUrl) {
        setImageState(prev => ({ ...prev, loading: false, error: true }));
        return;
      }

      const img = document.createElement('img');
      img.src = `${imageUrl}?t=${Date.now()}`; // Cache buster

      img.onload = () => {
        if (mounted) {
          setImageState({ loading: false, error: false, src: imageUrl });
        }
      };

      img.onerror = () => {
        if (mounted) {
          setImageState(prev => ({ ...prev, loading: false, error: true }));
        }
      };

      return () => {
        mounted = false;
        img.onload = null;
        img.onerror = null;
      };
    }, [item.gfMultiImages]);

    const renderImage = () => {
      if (imageState.loading) {
        return (
          <ImageLoadingContainer>
            <Skeleton
              variant="rectangular"
              width="100%"
              height="100%"
              animation="wave"
            />
          </ImageLoadingContainer>
        );
      }

      if (imageState.error) {
        return (
          <ImageLoadingContainer>
            <Typography color="text.secondary">Image not available</Typography>
          </ImageLoadingContainer>
        );
      }

      return (
        <Image
          src={imageState.src}
          alt={item.name}
          onError={() => setImageState(prev => ({ ...prev, error: true }))}
        />
      );
    };

    const renderPriceSection = () => (
      <PriceContainer>
        <PriceText size={size}>${formattedPrice}</PriceText>
        {formattedRegularPrice &&
          formattedRegularPrice &&
          item.salePrice < parseFloat(formattedRegularPrice) && (
            <OriginalPriceText>${formattedRegularPrice}</OriginalPriceText>
          )}
      </PriceContainer>
    );

    const renderExpiryInfo = () => {
      if (!daysLeft) return null;

      const isExpiringSoon = daysLeft <= 2;
      const label =
        daysLeft === 0
          ? 'Expires Today'
          : daysLeft === 1
          ? '1 day left'
          : `${daysLeft} days left`;

      return (
        <ExpiryChip
          label={label}
          className={isExpiringSoon ? 'expiring-soon' : ''}
          size="small"
        />
      );
    };

    return (
      <StyledCard elevation={0} size={size}>
        <Box
          sx={{
            position: 'absolute',
            top: size === 'small' ? 8 : 12,
            left: size === 'small' ? 8 : 12,
            zIndex: 2,
            display: 'flex',
            gap: 1,
          }}
        >
          {item.isExclusive && (
            <StyledChip label="Exclusive" className="exclusive" size="small" />
          )}
          {discount && discount > 0 && (
            <StyledChip
              label={`${discount}% Off`}
              className="discount"
              size="small"
            />
          )}
        </Box>

        <ImageContainer className="image-container" size={size}>
          {renderImage()}
          {renderExpiryInfo()}
        </ImageContainer>

        <ContentWrapper size={size}>
          <Box sx={{ mb: size === 'small' ? 0.75 : 1 }}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 600,
                fontSize: size === 'small' ? '0.9rem' : '1.125rem',
                lineHeight: 1.3,
                color: theme => theme.palette.text.primary,
                mb: 0.5,
                textTransform: 'capitalize',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                height: size === 'small' ? '2.34rem' : '2.925rem', // 1.3 (lineHeight) * fontSize * 2 lines
              }}
            >
              {item.name}
            </Typography>
            {item.nameChinese && (
              <Typography
                variant="body2"
                sx={{
                  color: theme => alpha(theme.palette.text.secondary, 0.8),
                  fontSize: size === 'small' ? '0.75rem' : '0.875rem',
                  lineHeight: 1.4,
                }}
                noWrap
              >
                {item.nameChinese}
              </Typography>
            )}
          </Box>

          {renderPriceSection()}

          <Box>
            <Typography
              variant="body2"
              sx={{
                color: theme => alpha(theme.palette.text.secondary, 0.9),
                fontSize: size === 'small' ? '0.75rem' : '0.875rem',
                mb: item.limitString ? '1px' : 0,
              }}
            >
              {quantityText}
            </Typography>

            {item.limitString && (
              <Typography
                variant="caption"
                sx={{
                  color: theme => theme.palette.warning.main,
                  fontWeight: 500,
                  fontSize: size === 'small' ? '0.7rem' : '0.75rem',
                  display: 'block',
                }}
              >
                Limit: {item.limitString}
              </Typography>
            )}
          </Box>
        </ContentWrapper>
      </StyledCard>
    );
  },
);

export default FlyerItemCard;
