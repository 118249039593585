import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  GfFlyerItem,
  ShowAt,
} from '@swagger/typescript-fetch-goflyer/dist/api';
import { apiService } from 'utils/api';
import FlyerItemCard, {
  FlyerItemCardSize,
} from 'app/mobile-desktop-common-components/FlyerItemCard';
import {
  EmptyStateContainer,
  ItemsGrid,
  LoadingContainer,
  ScrollContainer,
} from './styled';

interface Props {
  storeIds: string[];
  size?: FlyerItemCardSize;
  hoursOffset?: number;
}

const PaginatedItems: React.FC<Props> = ({ storeIds, size, hoursOffset }) => {
  const [items, setItems] = useState<GfFlyerItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [initialized, setInitialized] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const fetchItems = useCallback(
    async (pageNum: number) => {
      try {
        const response =
          await apiService.gfFlyerItemControllerFindAllByStoreIds(
            storeIds,
            ShowAt.Supermarket,
            pageNum,
            20,
            hoursOffset,
          );

        if (pageNum === 1) {
          setItems(response.results || []);
        } else {
          setItems(prev => [...prev, ...(response.results || [])]);
        }

        setHasMore((response.results || []).length === 20);
        setError(null);
      } catch (err) {
        setError('Failed to load flyer items. Please try again later.');
        console.error('Error fetching flyer items:', err);
      } finally {
        setLoading(false);
        if (!initialized) setInitialized(true);
      }
    },
    [storeIds, initialized, hoursOffset],
  );

  const loadMore = useCallback(() => {
    if (!loading && hasMore) {
      setPage(prev => prev + 1);
    }
  }, [loading, hasMore]);

  useEffect(() => {
    fetchItems(page);
  }, [fetchItems, page]);

  if (error) {
    return (
      <EmptyStateContainer>
        <Typography color="error" variant="h6">
          {error}
        </Typography>
      </EmptyStateContainer>
    );
  }

  if (!initialized && loading) {
    return (
      <LoadingContainer>
        <CircularProgress />
        <Typography variant="body1" sx={{ mt: 2 }}>
          Loading items...
        </Typography>
      </LoadingContainer>
    );
  }

  if (initialized && items.length === 0) {
    return (
      <EmptyStateContainer>
        <Typography variant="h6" color="text.secondary">
          No items found
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
          There are no items available at the moment.
        </Typography>
      </EmptyStateContainer>
    );
  }

  return (
    <Box
      sx={{
        width: '100%',
        position: 'relative',
        overflow: 'hidden',
        height: '100vh',
      }}
    >
      <ScrollContainer id="scrollableDiv" ref={scrollContainerRef}>
        <InfiniteScroll
          dataLength={items.length}
          next={loadMore}
          hasMore={hasMore}
          loader={
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
              <CircularProgress size={24} />
            </Box>
          }
          scrollThreshold={0.9}
          style={{
            overflowY: 'auto',
            padding: '15px 5px',
          }}
          scrollableTarget="scrollableDiv"
        >
          <ItemsGrid size={size}>
            {items.map((item, index) => (
              <FlyerItemCard key={item.id || index} item={item} size={size} />
            ))}
          </ItemsGrid>
        </InfiniteScroll>
      </ScrollContainer>
    </Box>
  );
};

export default PaginatedItems;
